.app-header {
    position: fixed;
    top: 0;
    z-index: 233;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 8px;
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
    color: var(--theme-color);
    background-color: var(--semi-black-color);
    box-shadow: 0 1px 1px var(--theme-color);
    animation: slide-in-down var(--animation-duration) ease-in-out forwards;
    user-select: none;
}

@media (max-width: 768px) {
    .app-header {
        padding: 0 4px;
    }
}

.app-title {
    font-weight: 500;
    font-size: 1.5rem;
}

.app-title-text {
    display: none;
    margin-left: 1rem;
}

.app-title-svg {
    display: inline-flex;
}

@media (min-width: 768px) {
    .app-title-text {
        display: inline-flex;
    }

    .app-title-svg {
        display: none;
    }
}

.app-header a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    min-height: 60px;
    padding: 0;
    color: white;
    transition: color var(--transition-duration) ease-in-out;
    user-select: none;
}

.app-tab {
    position: relative;
    transform: translateY(-100%);
    animation-name: slide-in-down;
    animation-duration: var(--animation-durationx2);
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.app-tab:nth-child(1) {
    animation-delay: 100ms;
}

.app-tab:nth-child(2) {
    animation-delay: 200ms;
}

.app-tab:nth-child(3) {
    animation-delay: 300ms;
}

.app-tab:nth-child(4) {
    animation-delay: 400ms;
}

.app-nav:hover .app-tab {
    color: white;
}

.app-nav .app-tab:hover {
    color: currentColor;
}

.app-nav > a:target {
    color: currentColor;
}

.app-tab::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: var(--theme-contrast-color);
    transform: scaleY(0);
    transform-origin: center 0;
    transition: transform var(--transition-duration) ease-in-out;
    content: "";
}

.app-tab:target::before,
.app-tab:hover::before {
    transform: scaleY(1);
}

:hover > .app-tab:target::before {
    transform: scaleY(0);
}

.app-tab:hover:target::before {
    transform: scaleY(1);
}

.app-title-svg > svg,
.app-tab > svg {
    width: 30px;
    height: 30px;
    margin: 0;
}
