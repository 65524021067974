@keyframes slide-in-right {
    from {
        transform: translate3d(100%, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slide-out-right {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slide-in-up {
    from {
        transform: translate3d(0, 100%, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slide-in-down {
    from {
        transform: translate3d(0, -100%, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
