html {
    box-sizing: content-box;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding-bottom: env(safe-area-inset-bottom);
}

body {
    box-sizing: border-box;
}

html,
body,
.app-container,
.app-main {
    display: flex;
    flex-direction: column;
}

body,
.app-container,
.app-main {
    flex: 1 0 auto;
}

body,
.app-container {
    display: contents;
    contain: size;
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports not ((-webkit-appearance: none) and (stroke-color: transparent)) {
        /* iOS Safari 10 and 11, and Safari 11 renders display:contents as display:inline. @supports will also report as true. */
        body,
        .app-container {
            display: flex;
        }
    }
}

.app-main {
    width: 80%;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 32px;
}

@media (max-width: 1280px) {
    .app-main {
        width: 1024px;
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .app-main {
        width: 100%;
        padding-right: env(safe-area-inset-right);
        padding-left: env(safe-area-inset-left);
    }
}
