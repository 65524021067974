.toast-queue {
    position: fixed;
    top: 60px;
    right: 0;
    width: 100%;
    max-width: 320px;
}

.toast {
    display: flex;
    max-height: 320px;
    margin: 1rem;
    color: var(--black);
    background-color: var(--semi-white-color);
    background-color: #ffffffdd;
    border: 1px solid var(--theme-color);
    border-radius: var(--border-radius);
    box-shadow: 0 0 1rem var(--theme-color);
    animation: height-change var(--animation-duration) ease forwards,
        slide-in-right var(--animation-duration) ease-in forwards,
        slide-out-right var(--animation-duration) ease-out 5s forwards;
}

.toast-badge {
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    margin: 8px;
}

.toast-info {
    color: #1b95e0;
}

.toast-success {
    color: #19cf86;
}

.toast-warning {
    color: #fab81e;
}

.toast-badge > svg {
    width: 100%;
    height: 100%;
}

.toast-text {
    margin: 1rem 0.5rem;
}

@keyframes height-change {
    from {
        max-height: 0;
    }

    to {
        max-height: 320px;
    }
}
