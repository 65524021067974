.lyric-list {
    position: relative;
    width: 100%;
    line-height: 1.4;
    user-select: none;
}

.lyric-list.on-screen-button {
    margin-bottom: 40px;
}

.space-button {
    position: fixed;
    bottom: 32px;
    left: 0;
    width: 100%;
    height: 40px;
    margin-bottom: env(safe-area-inset-bottom);
    color: var(--white);
    background-color: var(--semi-black-color);
    border-bottom: 1px dashed var(--theme-color);
}

.space-button:active {
    border-bottom-style: solid;
}

.line {
    align-items: baseline;
    padding: 8px 0 8px 8rem;
    font-size: 1.2rem;
    white-space: pre-wrap;
    border-top: solid 2px transparent;
    border-bottom: solid 2px transparent;
}

.line::after {
    display: inline-block;
    content: "";
}

.line:nth-child(even) {
    background-color: #00000022;
}

.line:nth-child(odd) {
    background-color: #ffffff22;
}

.line > .line-time,
.line > .line-text {
    pointer-events: none;
}

.select {
    border-color: var(--theme-color);
}

.curser {
    position: absolute;
    left: 0;
    contain: content;
}

.curser::after {
    content: "\27A4";
}

.line.highlight {
    color: var(--theme-contrast-color);
    background-color: var(--theme-color);
}

.error::before {
    position: absolute;
    left: -2rem;
    content: "\26A0\FE0F";
}

@media (max-width: 1080px) {
    .error::before {
        left: 6rem;
    }

    .select.error::before {
        visibility: hidden;
    }
}

@media (max-width: 768px) {
    .line {
        padding-left: 6rem;
        font-size: 1rem;
        border-width: 1px;
    }

    .error::before {
        left: 4rem;
    }
}
